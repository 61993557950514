import newportStandard from './themes/newport-standard';
import { ThemeOptions } from '@material-ui/core/styles';
//import newportXmas from './themes/newport-xmas';
//import newportBlackfriday from './themes/newport-blackfriday';

interface CustomPalette {
    themeColor: string;
    background: string;
    color: string;
    text: string;
    main: string;
    dark: string;
    darker: string;
    darkest: string;
    light: string;
    lighter: string;
    lightest: string;
    backgroundContrast: string;
    purchase: {
        background: string;
        hover: string;
    };
    disabled: string;
    highlight: string;
    lowlight: string;
    altTitle: string;
    special: string;
    blue: string;
}

interface CustomTypography {
    fontFamily: string;
    fontWeight?: number;
    fontSize?: number;
}

// @ts-ignore
interface CustomTheme extends ThemeOptions {
    name: string;
    palette: {
        primary: CustomPalette;
        secondary: {
            main: string;
            text: string;
        };
        error: {
            main: string;
            text: string;
        };
        header: {
            background: string;
            backgroundContrast: string;
            color: string;
            altColor: string;
            icons: {
                color: string;
                badge: {
                    color: string;
                    background: string;
                };
            };
            menu: {
                background: string;
                backgroundContrast: string;
                highlight: string;
                color: string;
                items: {
                    level0: string;
                    level1: string;
                    level2: string;
                    level3: string;
                };
                altColor: string;
                arrows: string;
            };
            search: {
                color: string;
                background: string;
            };
        };
        footer: {
            color: string;
        };
        background: {
            body: string;
            element: string;
        };
        text: {
            black: string;
            grey: string;
        };
    };
    typography: {
        fontFamily: string;
        fontWeight?: number;
        headerFont: string;
        h1: CustomTypography;
        h2: CustomTypography;
        h3: CustomTypography;
        h4: CustomTypography;
        h5: CustomTypography;
        h6: CustomTypography;
        subtitle1: CustomTypography;
        subtitle2: CustomTypography;
        body1: CustomTypography;
        body2: CustomTypography;
        button: CustomTypography;
        productPrice: CustomTypography;
        dropdown: CustomTypography & { textTransform: string };
        caption: CustomTypography;
        overline: CustomTypography;
        menu: {
            serif: CustomTypography & { letterSpacing: string };
            sansSerif: Partial<CustomTypography>;
        };
    };
    cmsContent: Record<string, any>;
}

const theme: CustomTheme = newportStandard;

//const theme = newportXmas
//const theme = newportBlackfriday

export default theme;
