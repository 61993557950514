const NextI18Next = require('next-i18next').default;
const config = require('../i18n-config');
const Nexti18n = new NextI18Next(config);

// Override t() function globally
const originalT = Nexti18n.i18n.t.bind(Nexti18n.i18n);

Nexti18n.i18n.t = (key, options = {}) => {
    const translation = originalT(key, options);

    // If translation is empty, try fallback to English
    if (translation === '') {
        return originalT(key, { ...options, lng: 'en' });
    }

    return translation;
};

module.exports = Nexti18n;
